// hero.scss
//
// Hero components

.hero {
  background: $color-primary;
  position: relative;

  &.hero--plain {
    @media (min-width: $md) {
      min-height: 310px;
      height: auto;
    }
    @media (min-width: $lg) {
      min-height: 320px;
      height: auto;
    }
  }

  &.hero--plain.hero--with-subnav {
    @media (min-width: $lg) {
      padding-bottom: 7px;
    }
  }
}

.hero__content {
  @include make-col();
  position: relative;
  z-index: 2;

  @media (min-width: $md) {
    @include make-col(6);
  }

  @media (min-width: $lg) {
    @include make-col(4);
  }

  .hero__content-inner {
    background: $color-primary;
    padding-top: 86px;
    padding-bottom: 20px;
    min-height: 185px;
    position: relative;

    @media (min-width: 320px) {
      padding-right: 15%;
    }

    @media (min-width: $md) {
      min-height: 310px;
      padding-top: 107px;
      padding-right: 0;
      padding-bottom: 25px;

      .hero--with-subnav & {
        // subnav has a -70px top margin, this fixes long headings that wrap to three lines
        padding-bottom: 85px;
      }
    }

    @media (min-width: $lg) {
      min-height: 380px;
      padding-top: 120px;

      .hero--plain & {
        min-height: 320px;
      }
    }
  }
}

.hero__heading {
  position: relative;
  z-index: 2;

  @media (min-width: $md) {
    width: 650px;
    min-height: 124px;
    display: flex;
    align-items: center;
  }

  @media (min-width: $lg) {
    width: 900px;
    min-height: 168px;
  }

  // @hack IE11 - https://github.com/philipwalton/flexbugs/issues/231
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:after {
      content: "";
      display: block;
      min-height: inherit;
      font-size: 0;
    }
  }

  h1 {
    color: #fff;
    margin-bottom: 1px;
    width: 100%;

    sup {
      font-family: $font-family-base;
      font-weight: 400;
      font-size: 0.4125em;
      position: relative;
      top: -1em;
      left: -0.025em;

      @media (min-width: $md) {
        font-size: 0.3em;
        top: -1.5em;
        left: -0.05em;
      }
    }

    @media (min-width: $md) {
      align-self: center;
    }
  }
}

.hero__intro {
  color: #fff;
  padding: 1.25em 30px 0 0;

  @media (min-width: $md) {
    padding-top: 1em;
  }
}

.hero__cta {
  margin: 2em 0 0;

  @media (min-width: $lg) {
    margin-top: 1.5em;
  }

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      border-bottom: 1px solid #fff;
    }
  }
}

.hero__banner {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 1;
  background-color: $gray-darker;

  @media (min-width: $md) {
    display: block;
    max-width: calc(50% + 35px);
    min-height: 310px;
  }

  @media (min-width: $lg) {
    max-width: calc(50% + 215px);
    min-height: 380px;
  }
}

.hero__img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: 20% 50%;
  background-color: $gray-darker;

  @media (min-width: $lg) {
    background-position:50% 50%;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.10) 80%, rgba(0,0,0,0.30) 100%),
                linear-gradient(280deg, rgba(0,0,0,0), rgba(0,0,0,0.10) 60%, rgba(0,0,0,0.40) 100%);

    @media (min-width: $md) {
      background: linear-gradient(0deg, rgba(0,0,0,0), rgba(0,0,0,0.10) 70%, rgba(0,0,0,0.50) 100%),
                  linear-gradient(280deg, rgba(0,0,0,0), rgba(0,0,0,0.10) 60%, rgba(0,0,0,0.40) 100%);
    }
  }
}
