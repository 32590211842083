// _pagination.scss
//
// Pagination styles.

.pagination {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (min-width: $md) {
    margin-bottom: 62px;
  }

  li {
    font-family: $font-family-display;
    font-size: rem(14);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: (0.2/14) * 1em;
  }

  a {
    text-decoration: none;
    display: inline-block;
  }

  .next,
  .prev {
    position: relative;
    padding: 0 22px;

    a::after {
      content: "";
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      transition: all $transition-link;
    }

    &.next a:hover:after {
      border-left-color: $color-secondary;
    }

    &.prev a:hover:after {
      border-right-color: $color-secondary;
    }
  }

  .next a:after {
    right: 0;
    @include triangle('right', 10px, 10px, $color-primary);
  }

  .prev a:after {
    left: 0;
    @include triangle('left', 10px, 10px, $color-primary);
  }

  .pages {
    display: none;

    @media (min-width: $md) {
      display: flex;
    }

    ul {
      display: flex;
      margin: 0;
      text-align: center;

      .current,
      .current a {
        color: $color-secondary;
        background: $white;
      }
    }
  }

  .pages a,
  .pages .empty {
    min-width: rem(32);
    padding: rem(5);
    text-align: center;
  }
}
