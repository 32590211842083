// header.scss
//
// Site header styles
//
// @todo - we'll need to make the logos swappable in the cms.

$logo-width-mobile: 120px;
$logo-width-tablet: 160px;
$logo-width: 173px;

$logo-top-mobile: 10px;
$logo-top-tablet: 13px;
$logo-top: 32px;

body.mobile-nav-active {
  height: 100%;
  overflow-y: hidden;

  .site-header, .site-header.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: none;
  }
}

// unfortunately there needs to be a duplicate search bar for the fixed header
body.site-search-active:not(.fixed-header-active) .site-search:not(.fixed),
body.site-search-active.fixed-header-active .site-search.fixed {
  position: relative;
  display: block;
  max-height: 82px;
}

// outer site wrapper is just to position site down when search bar is active
.site-wrapper {
  position: relative;
}

// search bar
.site-search {
  position: absolute;
  background: $color-secondary;
  color: $white;
  max-height: 0;
  transition: all 0.3s;
  overflow: hidden;

  .container {
    position: relative;
  }

  form {
    padding: 17px 0 23px;
  }

  .search-input {
    font-family: $font-family-headline;
    font-size: rem(32);
    text-transform: uppercase;
    line-height: (40/32);
    padding: 0;
    color: $white;

    &::placeholder {
      color: $white;
    }

    &:focus {
      border-color: $white;
    }
  }

  .close {
    position: absolute;
    top: 22px;
    right: 12px;
    @include reset-button();
    border: 0;
    background: none;
    font-size: 20px;

    .icon-close {
      color: $white;
    }

    @media (min-width: $sm) {
      right: 22px;
    }
  }
}

// now the actual site header begins
.site-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  min-height: 54px;

  @media (min-width: $md) {
    min-height: 112px;
  }

  @media (min-width: $lg) {
    min-height: 124px;
  }

  &:not(.inverted) {
    //background: $color-primary;
  }

  &.fixed {
    position: fixed;
    background-color: $color-primary;
  }

  .container {
    position: relative;
  }

  .branding {
    display: block;
    width: $logo-width-mobile; // @todo
    padding-top: $logo-top-mobile;
    position: relative;
    z-index: 100;

    img {
      width: 100%;
      height: auto;
    }

    @media (min-width: $md) {
      width: $logo-width-tablet; // @todo
      padding-top: $logo-top-tablet;
    }
    @media (min-width: $lg) {
      width: $logo-width; // @todo
      padding-top: $logo-top;
    }

  }

  // mobile navigation
  .nav-mobile {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: $color-primary;
    z-index: 99;
    padding: 54px 0 0;

    @media (min-width: $md) {
      padding-top: 112px;
    }

    &.is-active {
      display: block;
    }

    ul ul {
      background-color: $color-primary;
      border-top: 2px solid rgba(0,0,0, 0.20);
    }

    li li {
      background-color: rgba(0,0,0, 0.15);
    }

    li li a {
      font-weight: $font-weight-medium;
    }

    > ul > li {
      border-top: 2px solid rgba(0,0,0, 0.15);
    }

    > ul > li.nav_mobile__inverted {
      background: $white;
      border-top: 0;

      > a {
        color: $color-primary;
      }

      a[data-toggle="collapse"]::after {
        @include triangle('down', 10px, 5px, $color-primary);
      }
    }

    > ul.nav-mobile__utility,
    li li {
      a {
        text-transform: none;
        font-weight: $font-weight-medium;
      }
    }

    a {
      color: #fff;
      font-family: $font-family-display;
      text-transform: uppercase;
      font-size: rem(16);
      font-weight: $font-weight-bold;
      display: block;
      padding: 13px 20px;

      .icon {
        font-size: 12px;
        position: relative;
        top: 1px;
        margin-left: 8px;
      }
    }

    .active a, a:hover {
      text-decoration: none;
      border: 0;
    }

    a[data-toggle="collapse"] {
      display: inline-block;
      position: relative;
      padding-right: 20px;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: calc(50% - 2px);
        @include triangle('down', 10px, 5px, $white);
      }
    }

    .nav-mobile__phone {
      background-color: rgba(0,0,0, 0.15);
      border-top: 2px solid rgba(0,0,0, 0.20);

      a {
        padding: 15px 20px;
      }

      .icon {
        font-size: 20px;
        position: relative;
        top: 5px;
        left: -7px;
        margin-right: 6px;
      }
    }
  }

  // desktop navigation
  .nav-main {
    display: none;
    position: absolute;
    top: 0;
    right: 30px;
    width: calc(100% - 250px);
    padding-top: 17px;

    @media (min-width: $lg) {
      display: flex;
      flex-direction: column;
    }

    &__utility, &__menu {
      display: flex;
      flex-wrap: wrap;
      margin-left: auto;
      align-items: center;
      align-self: flex-end;

      ul  {
        display: none;
      }

      li {
        margin-left: 2em;
        position: relative;
      }

      > li > a:not(.btn) {
        font-family: $font-family-display;
        font-size: rem(17);
        line-height: rem(21);
        color: #fff;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        display: inline-block;

        .icon {
          font-size: 13px;
          position: relative;
          top: 2px;
          margin-left: 2px;
        }
      }

      a:hover, a:focus {
        text-decoration: none;

      }

      > li > a:not(.btn) {
        border-bottom: 1px solid transparent;
        transition-property: all;

        &:hover, &:focus {
          border-bottom: 1px solid #fff;
        }
      }
    }

    &__utility {
      margin-right: -3px;

      li {
        margin-left: 0.9em;

        @media (min-width: 1020px) {
          margin-left: 1em;
        }

        @media (min-width: 1060px) {
          margin-left: 1.5em;
        }
      }

      li:first-child {
        margin-left: 0;
      }
    }

    a[data-toggle="dropdown"] {
      display: inline-block;
      position: relative;
      padding-right: 20px;

      &.btn {
        padding-right: 36px;
        min-width: 140px;

        &::after {
          right: 31px; // 17px but "MY SBA" is really short
        }
      }

      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: calc(50% - 3px);
        @include triangle('down', 10px, 5px, $white);
      }

      &.btn:hover::after,
      &.btn:focus::after {
        @include triangle('down', 10px, 5px, $color-primary);
      }
    }

    .dropdown-menu {
      background: $color-primary;
      color: $white;
      min-width: 166px;
      right: 0;
      left: auto;
      margin-top: 10px;
      margin-right: -30px;
      box-shadow: 0 5px 50px 0 rgba(0,0,0,0.28);
      border: 1px solid rgba(0,0,0,0.28);

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: -10px;
        right: 25px;

      }

      &::before {
        top: -11px;
        right: 24px;
        @include triangle('up', 22px, 11px, rgba(0,0,0,0.28));
      }

      &::after {
        @include triangle('up', 20px, 10px, $color-primary);
      }

      .dropdown-item {
        color: $white;
        display: block;
        padding: 19px 30px;
        font-family: $font-family-display;
        font-size: rem(16);
        line-height: (21/16);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        white-space: nowrap;
        position: relative;

        .icon {
          font-size: 13px;
          color: #000;
          opacity: 0.35;
          position: absolute;
          right: 30px;
          top: calc(50% - 7px);
        }

        &.external {
          padding-right: 70px;
        }

        &--wide {
          white-space: normal;
          min-width: 380px;
        }
      }

      .dropdown-item:not(:last-child) {
        border-bottom: 1px solid rgba(0,0,0, 0.25);
      }
    }

    .btn + .dropdown-menu {
      right: 17px;
    }

    &__utility {
      margin-bottom: 18px;

      > li > a:not(.btn) {
        font-size: rem(16);
        line-height: (18/16);
        font-weight: $font-weight-medium;
        text-transform: none;
      }

      .btn {
        margin-top: 2px;
      }
    }

    &__menu {

    }
  }

  // reverse button on solid background
  &.inverted, &.fixed {
    .btn {
      background-color: $white;
      color: $color-primary;
    }

    a.btn[data-toggle="dropdown"]::after {
      @include triangle('down', 10px, 5px, $color-primary);
    }
  }

  // mobile search icon
  .nav-toggle__search {
    position: absolute;
    top:11px;
    right: 58px;
    color: $white;
    z-index: 100;
    width: 30px;
    height: 23px;
    font-size: 22px;

    @media (min-width: $md) {
      top: 32px;
      right: 88px;
      font-size: 26px;
    }
  }

  // hamburgler
  .nav-toggle {
    position: absolute;
    top:13px;
    right: 18px;
    cursor: pointer;
    width: 30px;
    height: 23px;
    z-index: 100;

    .icon-bar {
      display: block;
      width: 30px;
      height: 3px;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(0deg);
      transform-origin: left center;
      transition: .3s ease-in-out;
    }
    .icon-bar:nth-child(2) {
      top: 10px;
    }
    .icon-bar:nth-child(3) {
      top: 20px;
    }
    &.is-active .icon-bar:nth-child(1) {
      transform: rotate(45deg);
      top: -1px;
      left: 4px;
    }
    &.is-active .icon-bar:nth-child(2) {
      width: 0%;
      opacity: 0;
    }
    &.is-active .icon-bar:nth-child(3) {
      transform: rotate(-45deg);
      top: 20px;
      left: 4px;
    }

    @media (min-width: $md) {
      width: 35px;
      height: 25px;
      top: 35px;
      right: 35px;
      .icon-bar {
        width: 35px;
      }
      .icon-bar:nth-child(2) {
        top: 11px;
      }
      .icon-bar:nth-child(3) {
        top: 22px;
      }
      &.is-active .icon-bar:nth-child(1) {
        top: -1px;
      }
      &.is-active .icon-bar:nth-child(3) {
        top: 24px;
      }
    }
  }
}

.nav-toggle, .nav-toggle__search, .nav-backdrop {
  @media (min-width: $lg) {
    display: none !important;
  }
}

.nav-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 98;
  background: rgba(0,0,0, 0.7);
}
