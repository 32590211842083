// wysiwyg.scss
//
// Typography adjustments for user-entered content.

.wysiwyg {
  ul:not([class]) {
    list-style: none;
    padding-left: 0;
    margin-bottom: rem(40);

    li {
      position: relative;
      padding-left: 40px;
    }

    li:before {
      content: "•";
      position: absolute;
      left: 20px;
    }

    ul {
      margin-top: rem(6);
    }
  }

  h6:not(:first-child) {
    margin-top: rem(40);
  }

  hr {
    margin: rem(27) 0;
  }

  &:not(:first-child) {
    margin-top: 40px;

    @media (min-width: $md) {
      margin-top: 60px;
    }
  }

  .lead + &,
  .subhead + & {
    margin-top: 2em;
  }
}

.box .wysiwyg,
.bg-white .wysiwyg,
.accordion__body .wysiwyg {

  ul:not([class]) {
    margin-bottom: .9375rem;
  }

  h6:not(:first-child) {
    margin-top: rem(20);
  }

  hr {
    border-color: $gray-lighter;
  }
}
