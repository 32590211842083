// Bootstrap-style Grid helpers
// Used internally in _grid.scss or can used as a mixin:
//
// Usage:
// .my-component-row { @include make-row(); }
// .my-component-column { @include make-col(3, 12); }
@mixin make-container() {
  width: 100%;
  padding-right: $container-gutter-width-sm; // 15px
  padding-left: $container-gutter-width-sm;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $md) {
    padding-right: $container-gutter-width;
    padding-left: $container-gutter-width; // 30px
  }
}
@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: ($grid-gutter-width-sm / -2);
  margin-left: ($grid-gutter-width-sm / -2);

  @media (min-width: $lg) {
    margin-right: ($grid-gutter-width / -2);
    margin-left: ($grid-gutter-width / -2);
  }
}
@mixin make-col($size: false, $columns: $grid-columns) {
  max-width: 100%;
  min-height: 1px;
  position: relative;
  width: 100%;
  padding-left: ($grid-gutter-width-sm / 2);
  padding-right: ($grid-gutter-width-sm / 2);
  @media (min-width: $lg) {
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
  }
  @if($size) {
    flex: 0 0 percentage($size / $columns);
    max-width: percentage($size / $columns);
  }
}
@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}
@mixin cancel-container-padding() {
  margin-right: -$container-gutter-width-sm;
  margin-left: -$container-gutter-width-sm;

  @media (min-width: $sm) {
    margin-right: -$container-gutter-width;
    margin-left: -$container-gutter-width;
  }
}

// Turn font smoothing off or on
// http://maximilianhoffmann.com/posts/better-font-rendering-on-osx

// Usage:

//   .dark-on-light { @include font-smoothing(off); }
//   .light-on-dark { @include font-smoothing(on); }
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// Vertical align an element within its parent
// http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
//
// Usage:
//
// .element p { @include vertical-align; }
@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

// Clearfix
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

// List Reset
//
// Usage:
// .element { @include list-reset(); }
//
@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    margin: 0;
    padding: 0;
    text-indent: 0;
  }
}

// Rem Sizing
//
// Usage:
// font-size: rem(24); // outputs 1.5rem
//
@function rem($size, $baseSize: 16) {
  $rem: $size / $baseSize;
  @return #{$rem}rem;
}

// Only display content to screen readers
//
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

// Reset button appearances

@mixin reset-button {
  -webkit-appearance: none;
  -moz-appearance: none;
}

// All text-based inputs
// https://github.com/thoughtbot/bourbon/blob/v5.1.0/core/bourbon/library/_text-inputs.scss
$text-inputs-list: (
  "[type='color']",
  "[type='date']",
  "[type='datetime']",
  "[type='datetime-local']",
  "[type='email']",
  "[type='month']",
  "[type='number']",
  "[type='password']",
  "[type='search']",
  "[type='tel']",
  "[type='text']",
  "[type='time']",
  "[type='url']",
  "[type='week']",
  "input:not([type])",
  "textarea",
);


$all-text-inputs: ();

@each $input in $text-inputs-list {
  $input: unquote($input);
  $all-text-inputs: append($all-text-inputs, $input, comma);
}

// Generate triangle in specified direction
// https://github.com/thoughtbot/bourbon/blob/v5.1.0/core/bourbon/library/_triangle.scss
//
// Usage:
// @include triangle("up", 2rem, 1rem, #b25c9c);
@mixin triangle(
  $direction,
  $width,
  $height,
  $color
) {
  @if not index(
    "up" "up-right" "right" "down-right" "down" "down-left" "left" "up-left",
    $direction
  ) {
    @error "Direction must be `up`, `up-right`, `right`, `down-right`, " +
           "`down`, `down-left`, `left` or `up-left`.";
  } @else if not _is-color($color) {
    @error "`#{$color}` is not a valid color for the `$color` argument in " +
           "the `triangle` mixin.";
  } @else {
    border-style: solid;
    height: 0;
    width: 0;

    @if $direction == "up" {
      border-color: transparent transparent $color;
      border-width: 0 ($width / 2) $height;
    } @else if $direction == "up-right" {
      border-color: transparent $color transparent transparent;
      border-width: 0 $width $width 0;
    } @else if $direction == "right" {
      border-color: transparent transparent transparent $color;
      border-width: ($height / 2) 0 ($height / 2) $width;
    } @else if $direction == "down-right" {
      border-color: transparent transparent $color;
      border-width: 0 0 $width $width;
    } @else if $direction == "down" {
      border-color: $color transparent transparent;
      border-width: $height ($width / 2) 0;
    } @else if $direction == "down-left" {
      border-color: transparent transparent transparent $color;
      border-width: $width 0 0 $width;
    } @else if $direction == "left" {
      border-color: transparent $color transparent transparent;
      border-width: ($height / 2) $width ($height / 2) 0;
    } @else if $direction == "up-left" {
      border-color: $color transparent transparent;
      border-width: $width $width 0 0;
    }
  }
}

@mixin ellipsis($max-width) {
  display: inline-block;
  max-width: $max-width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}