// _locations.scss
//
// Location listing and map styles.

.locations__map-wrapper {
	display: none;
	background: #a0a09a url(/assets/img/loader.gif) no-repeat center center;
	color: #a0a09a; // hide loading text

	@media(min-width: $sm) {
		display: block;
		background-position: 50% 58%;
	}

	@media(min-width: $md) {
		background-position: 50% 56%;
	}

  // Infowindow
  .gm-style .gm-style-iw-c {
    background: #fff;
    border-bottom: 5px solid $color-primary;
    border-radius: 0;
    width: 220px;
    max-width: unset !important;
    max-height: unset !important;
    padding: 28px 0 0 !important;
    overflow: hidden;
    box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.07);
  }

  // bottom triangle
  .gm-style .gm-style-iw-tc::after {
    background-color: $color-primary;
  }

  // disable default scrolling
  .gm-style .gm-style-iw-d {
    overflow: hidden !important;
  }

  // close button
  .gm-style .gm-style-iw button {
    position: absolute !important;
    color: $color-primary;
    top: -3px !important;
    right: -3px !important;

    >span {
      background-color: $color-primary;
    }
  }

  // content
  .infobox-content {
    padding: 0 17px 14px;
    font-family: $font-family-base;
    font-size: 12px;
    line-height: 1.5em;
    font-weight: normal;
    color: $color-navy;
    max-height: 250px;
    width: 100%;
    overflow-y: auto;

    @media screen and (min-width: $lg) {
      max-height: 430px;
    }

    hr {
      margin: 10px 0;
    }

    h5 {
      margin: 0 0 4px;
      font-size: 12px;
      font-weight: 700;
      color: $text-color;
    }

    a {
      color: $link-color;
      text-decoration: none;
      @include ellipsis(100%);

      &:hover {
        color: $link-color;
        text-decoration: underline;
      }
    }

    a.more {
      display: inline-block;
      margin-top: 0.75em;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      color: $link-color;
    }
  }
  // END Infowindow
}

.locations__map {
  @extend %embed-responsive;
}
