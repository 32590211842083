// Global site alert

.site-alert {
  position: relative;
  background: $color-secondary;
  z-index: 100;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  .alert__body {
    display: flex;
    align-items: center;
    padding: rem(25);

    p {
      margin: 0;
      font-weight: $font-weight-bold;
      color: $white;
    }
  }

  .alert__icon {
    margin-right: rem(15);
    position: relative;
    top: 2px;

    // set explicit size for IE11
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .alert__action {
    text-transform: none;
    color: $white;
    position: relative;
    display: inline-block;
    margin-left: 15px;

    &:hover {
      text-decoration: underline;
    }

    .icon {
      font-size: 12px;
      margin-left: 5px;

      &.icon-internal-alt {
        transform: rotate(45deg);
      }
    }
  }

  .alert__close {
    @include reset-button();
    padding: 5px;
    border: 0;
    color: $white;
    align-self: flex-end;
    margin-left: auto;
    align-items: center;
    display: flex;
    font-size: rem(24);
    font-weight: bold;
    background: $color-secondary;
    outline: 0;
    cursor: pointer;
    font-size: 20px;
    position: relative;
    right: -2px;
  }

  @media (max-width: $md) {
    .alert__icon {
      display: none;
    }

    .alert__body {
      padding: rem(15);
      display: block;
      padding-right: 50px;

      p {
        display: inline;
      }
    }

    .alert__action {
      display: inline;
      margin-left: 5px;
    }

    .alert__close {
      position: absolute;
      top: 13px;
      right: 8px;
    }
  }
}

.site-alert + .site-search {
  border-top: 1px solid rgba($white, 0.2);
}

body.mobile-nav-active .site-alert {
  display: none;
}