// grid.scss
//
// Grid is used to define common grid components for the project.
// This produces a 12 column flexbox grid with breakpoint prefixes.
//
// Example:
//
// <div class="container">
//   <div class="row">
//     <div class="col-content">My Content</div>
//     <div class="col-sidebar">My Sidebar</div>
//   </div>
// </div>
//
// Or with generic utility classes:
//
// <div class="container">
//   <div class="row">
//     <div class="col col-sm-4">1/3</div>
//     <div class="col col-sm-8">2/3</div>
//   </div>
// </div>

.container {
  @include make-container();
}

.container {
  max-width: $container-max-width;
}

.row {
  @include make-row();

  &.no-gutters {
    margin-left: 0;
    margin-right: 0;

    > .col,
    > [class*="col-"] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.col,
.col-content,
.col-content-wide,
.col-sidebar {
  @include make-col();
}

.col-content {
  order: 2;

  @media (min-width: $lg) {
    @include make-col(8);

    &.col-content--push {
      @include make-col-offset(4);
    }
  }
}

.col-sidebar {
  order: 1;

  @media (min-width: $md) {
    &.col-sidebar--split {
      @include make-col(6);
    }
  }

  @media (min-width: $lg) {
    @include make-col(4);

    &.col-sidebar--split {
      @include make-col(4);
    }
  }
}

.row-thirds {
  @include make-row();

  .col {
    @media (min-width: $md) {
      @include make-col(4);
    }
  }
}

// Generic utility helper classes
@for $i from 1 through $grid-columns {
  .col-#{$i} {
    @include make-col($i);
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    @for $i from 1 through $grid-columns {
      .col-#{$breakpoint}-#{$i} {
        @include make-col($i);
      }
    }
  }
}

// This can be be used to arbitrarily break columns to a new row.
// Use in combination with responsive utilities.
.w-100 {
  width: 100% !important;
}
