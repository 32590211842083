// _bio.scss
//
// Leadership bio pages

.bio {
	margin-top: 20px;
}


// photo in sidebar
.bio-photo {
  margin: 0 0 20px 0;

  @media (min-width: $lg) {
    margin-left: -32px;
  }
}

// small/med display of name and title
.bio-title-sidebar {
	display: flex;
	align-items: center;
}

.bio-title-sidebar__align {
	margin-bottom: 1rem;

	@media (min-width: $md) {
		padding-left: calc(32px - 11px);
		padding-right: 48px;
	}
}

.bio-title-header.page-content {
	margin-bottom: 0 !important;
}

.bio-title {
	margin-bottom: 0.75rem;

	@media (min-width: $lg) {
		font-size: rem(40);
	}
}

.bio-subtitle {
	margin: 0;
	font-weight: $font-weight-semibold;
	color: $text-color;

	@media (min-width: $md) {
		font-size: rem(20);
	}
}