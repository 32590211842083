//
// Station layout adjusments
//
// 1. Header
// 2. Footer
// 3. Hero
// 4. Sidebar
// 5. News

.station {

	//
	// 1. Header -------------------------------
	//

	.nav-main--station {
		padding-top: 50px;
	}

	.nav-mobile--station {}

	//
	// 2. Footer -------------------------------
	//

	.site-footer--station {
		padding: rem(40) 0 rem(20);

		.col--footer {
			@media (min-width: $md) {
				@include make-col(4);
			}
		}
	}

	.nav-footer--station {
		margin-bottom: rem(30);

		li {
			margin: rem(12) 0;
		}

		.menu--primary {
			a {
			  display: inline-block;
			  font-family: $font-family-display;
			  font-weight: $font-weight-heavy;
			  font-size: rem(18);
			  line-height: (24/18);
			  text-transform: uppercase;
			  position: relative;

			  @media (min-width: $nav-footer-break) {
					font-size: rem(20);
					line-height: (26/20);
			  }

			  .icon {
					font-size: 15px;
					position: relative;
					top: 2px;
					margin-left: 7px;
			  }
			}
		}

		.menu--secondary {
			.icon {
			  font-size: 10px;
			  position: relative;
			  top: 1px;
			  margin-left: 7px;
			}
		}
	}

	.nav-footer__info--station {
		@include make-row();

		.col {
			@include make-col();
			margin-bottom: rem(18);
		}

		p {
		  margin-bottom: 0.675em;
		}
	}

	.nav-footer__social--station {
		li {
		  display: inline-block;
		}

		li + li {
		  margin-left: 20px;
		}
	}

	//
	// 3. Hero -------------------------------
	//

	.hero.hero--station {
		min-height: 380px;

		@media (min-width: $md) {
			min-height: 560px;
		}

		@media (min-width: $lg) {
			min-height: 560px;
		}

		.container {
			position: relative;
		}

		.hero__content {
			padding-top: 120px;
			@include make-col();

			@media (min-width: $md) {
				padding-top: 180px;
			}

			@media (min-width: $lg) {
				padding-top: 228px;
			}
		}

		.hero__heading {
			top: -20px;
      min-height: 1px;
      align-items: flex-start;

			@media (min-width: $md) {
				top: -35px;
			}

			@media (min-width: $lg) {
				top: -45px;
			}

      h1 {
        align-self: flex-start;
      }
		}

		.hero__img {
			width: 100%;
			height: 260px;
			top: 120px;
			left: 0;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 50%;
				background-image: linear-gradient(180deg, rgba(0,0,0,.7), rgba(0,0,0,0));
			}

			@media (min-width: $md) {
				top: 180px;
				height: 380px;
			}

			@media (min-width: $lg) {
				top: 228px;
				height: 450px;
			}
		}
	}

  .hero--plain .hero__content {
      padding-top: 100px; // 120-20
      padding-bottom: 30px;

      @include make-col();

      @media (min-width: $md) {
        padding-top: 112px; // 180-68
        padding-bottom: 40px;
      }

      @media (min-width: $lg) {
        padding-top: 138px; // 228-90
      }
    }

	.station-content--home {
		@media (min-width: $lg) {
			.col-sidebar {
				padding-top: 25px;
			}

			.col-content {
				padding-top: 128px;
			}
		}
	}

	//
	// 4. Sidebar -------------------------------
	//

	.station-content {

		.col-content {
			@media (min-width: $md) {
				order: 1;
			}
		}

		.col-sidebar {
			margin-top: 20px;

			@media (min-width: $md) {
				margin-top: 40px;
			}

			@media (min-width: $lg) {
				order: 2;
				margin-top: 0;
			}

			.box {
				@media (min-width: $md) {
					&:first-child {
					    margin-top: 0;
					}
				}

				@media (min-width: $lg) {
					margin-left: 0px;

					&:first-child {
					    margin-top: -70px;
					}

					&.box--top {
						margin-top: 48px;
					}
				}
			}

			.box__header {
				border-bottom: 2px solid $gray-lighter;
				margin: -20px -20px 20px -20px;
				padding: 20px 20px;

				@media (min-width: $md) {
					margin: -30px -30px 30px -30px;
					padding: 24px 30px;
				}

				h2 {
					margin: 0;
				}
			}

			#box__map {
				@extend %embed-responsive;
				padding-bottom: 100%; // square
				margin-bottom: 30px;
			}
		}
	}

	// 5. News
	.home-articles {
		margin-top: 60px;

		@media (min-width: $md) {
			margin-top: 90px;
		}
	}

}
