// home.scss
//
// Home page-specific layout styles.


// hero
.hero--home {
  @media (max-width: $md - 1px) {
    padding-top: 320px;
  }
}

.hero--home,
.hero--home .hero__content-inner {

  @media (min-width: $md) {
    min-height: 580px;
  }
  @media (min-width: $lg) {
    min-height: 623px;
  }
}

.hero--home {
  .hero__content-inner {
    padding-top: 0;

    @media (max-width: $md - 1px) {
      background-color: transparent;
      padding-bottom: 25px;
    }

    @media (min-width: 320px) {
      padding-right: 30px;
    }

    @media (min-width: $md) {
      padding-top: 136px;
    }

    @media (min-width: $lg) {
      padding-top: 166px;
    }
  }

  .hero__heading {

    @media (max-width: $md - 1px) {
      margin-top: -54px;
    }

    @media (min-width: $md) {
      min-height: 181px;
    }

    @media (min-width: $lg) {
      width: 725px;
    }
  }

  .hero__heading h1 {
    @media (min-width: $md) {
      font-size: rem(64);
      line-height: (60/64);
      letter-spacing: (0.6/42) * 1em;
    }
  }

  .hero__banner {
    display: block;

    @media (max-width: $md - 1px) {
      height: 320px;
    }
  }
}


// carousel adjustments
.hero .hero-carousel__heading .slide {
  display: flex;
  align-items: center;
}

.hero.is--carousel {

  .hero-carousel__heading,
  .hero-carousel__intro,
  .hero-carousel__banner {
    overflow: hidden;

    .slide {
      position: relative;

      &:focus {
        outline: 0;
      }
    }

    .slide {
      display: none;
    }

    .slide.slick-slide {
      display: block;
    }
  }

  .hero-carousel__heading {
    display: flex;

    .slick-list {
      margin-left: -2px; // tweak for artifacts
    }

    .slick-track {
      display: flex;
    }

    .slide {
      display: none;
      width: 100%;
      height: 100%;
      align-self: center;
      flex-direction: column;
      height: 100%;
      margin-left: 2px; // tweak for artifacts
    }

    .slide.slick-slide {
      display: flex;
    }
  }

  .hero-carousel__banner {
    .js & {
      display: none;
    }

    .slick-list, .slick-track {
      height: 100%;
    }
  }

  .hero-carousel__control .slick-dots {
    bottom: 25px;
    z-index: 1;

    @media (min-width: 320px) {
      padding-right: 30px;
    }
  }

  .hero__cta .more {
    position: relative;
    z-index: 2;
  }
}

.home-content {
  margin-top: 40px;

  @media (min-width: $lg) {
    margin-top: 48px;
  }

  // smaller breakpoint and right sidebar
  @media (min-width: $md) {
    .col-content {
      @include make-col(8);
      order: 1;
    }

    .col-sidebar {
      @include make-col(4);
      order: 2;
    }
  }

  // sidebar box overrides
  .box {
    margin: 0 0 30px 0 !important;
  }

  .box:first-child {
    position: relative;
    z-index: 2;

    @media (min-width: $md) {
      margin-top: -(82px + 40px) !important;
    }

    @media (min-width: $lg) {
      margin-top: -(140px + 40px) !important;
    }
  }

  .box__header {
    border-bottom: 2px solid $gray-lighter;
    margin: -20px -20px 20px -20px;
    padding: 20px 20px;

    @media (min-width: $md) {
      margin: -30px -30px 30px -30px;
      padding: 24px 30px;
    }

    h2 {
      margin: 0;
    }
  }

  .tracking-form {
    .row, .col {
      margin-top: 0;
    }

    .row + .row {
      margin-top: 20px;
    }

    .form-actions {
      text-align: right;
    }
  }
}

.home-articles {
  margin-top: 0;

  h2 {
    @media (min-width: $md) {
      margin-bottom: rem(32);
    }
  }

  @media (max-width: $lg - 1px) {
    &.articles .article__info,
    &.articles .article__body {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

.home-locations {
  padding-top: 46px;
  padding-bottom: 40px;
  background: $white;

  @media (min-width: $md) {
    padding-top: 32px;
    padding-bottom: 70px;
    background: $gray-lighter;
  }

  &__intro {
    @media (min-width: $lg) {
     max-width: calc(66.66667% - $grid-gutter-width); // match content
    }
  }

  .locations__map-wrapper {
    display: none;
    margin-top: 52px;

    @media (min-width: $md) {
      display: block;
    }
  }
}
