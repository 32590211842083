// _search.scss
//
// Search results styles

.search-results {
  margin: rem(25) 0 rem(36);

  @media (min-width: $lg) {
    margin-top: 0;
  }
}

.search-result {
  margin-bottom: rem(25);

  @media (min-width: $lg) {
    margin-bottom: rem(38);
  }

  a {
    color: $color-primary;
  }

  a:hover, a:focus {
    color: $color-secondary;
  }
}
