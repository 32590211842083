// forms.scss
//
// Form Styling

form, fieldset {
  margin: 0;
}


#{$all-text-inputs},
textarea,
select {
  font-size: 1rem;
  line-height: normal;
  margin: 0;
  padding: 0 0 10px 0;
  border: 0;
  border-bottom: 1px solid $gray;
  display: block;
  width: 100%;
  max-width: 100%;
  //height: 50px;
  background: transparent;
  border-radius: $input-border-radius;
  color: $color-secondary;
  font-weight: inherit;
  appearance: none;

  &:focus,
  &.has-input {
    outline: 0;
    border-color: $input-active-color;
  }

  &::placeholder,
  &.placeholder {
    color: $gray-alt;
  }

  &:disabled,
  &.disabled,
  &:readonly,
  &.readonly {
    cursor: not-allowed;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $color-secondary;
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
  background: transparent;
}

textarea {
  height: auto;
  line-height: 1.5;
  resize: none;
  overflow-y: auto;
}

label,
.label {
  @extend %h5;
  color: $input-active-color;
  display: block;
  //margin: 0;
  transition: opacity 0.5s;

  .js &.is-placeholder {
    // only enable this horrible placheolder UX if javascript is enabled
    opacity: 0;
  }

}

// regular text inline labels used mostly with checkboxes/radios inline
.label-inline {
  display: inline-block;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  letter-spacing: normal;
  line-height: $line-height-base;
  text-transform: none;
  color: $gray-alt;
  margin: 0.25rem 30px 0.25rem 0;
  opacity: 1;
}

// suffix text after inputs, e.g. "USD"
input.input-suffix, select.input-suffix {
  width: calc(100% - 45px);
  display: inline-block;
}

.input-suffix-text {
  color: $gray-alt;
  display: inline-block;
  margin-left: 5px;
}

// Default file upload
input[type="file"] {
  font-size: 15px;
  line-height: 1;
  color: $text-color-light;
  outline: 0;
}

// Custom select styles

.custom-select,
%custom-select {
  display: inline-block;
  appearance: none;
  width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  padding-right: 28px;
  background: url(../svg/icon-dropdown.svg) no-repeat 100% calc(50% - 5px);
  background-size: 10px 10px;

  &::-ms-expand {
    display: none;
  }

  &--alt {
    padding-bottom: 5px;
    font-weight: $font-weight-semibold;
    background: url(../svg/icon-dropdown-blue.svg) no-repeat 100% calc(50% - 2px);

  }
}

.select-inline {
  display: inline-block;
  margin-right: 35px;
  width: auto !important;
}

// custom checkbox and radio styles

.checkbox-inline, .radio-inline {
  display: inline-block;
  margin-right: 35px;
}

.checkbox, .radio {
  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  label {
    position: relative;
    padding-left: 30px;
    display: inline-block;
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    letter-spacing: normal;
    line-height: $line-height-base;
    text-transform: none;
    color: $gray-alt;
    margin: 0.25rem 0;
    opacity: 1;

    &::before, &::after {
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      top: 4px;
      left: 0;

    }

    &::before {
      background-color: $gray-lighter;
      border: 1px solid $gray;
    }
  }

  input:checked ~ label {
    color: $color-secondary;
  }
}

.radio {
  label {

    &::before, &::after {
      border-radius: 50rem;
    }

    &::after {
      transform: scale(0.7);
      border-radius: 50rem;
    }
  }

  input:checked ~ label::before {
    background-color: $white;
    border-color: $input-active-color;
  }

  input:checked ~ label::after {
    background-color: $input-active-color;
  }
}

.checkbox {
  label {

    &::before, &::after {

    }
  }

  input:checked ~ label::after {
    background: $input-active-color url(../svg/icon-check-white.svg) no-repeat 3px 4px;
    background-size: 8px 8px;
    border-color: $input-active-color;
  }
}

.radio-group, .checkbox-group, .inline-group {
  // align with normal fields when using inline label
  padding-top: rem(15);
}

// Layout (use grid classes)

form {
  .row + .row {
    margin-top: rem(26);

    @media (min-width: $lg) {
      margin-top: rem(40);
    }
  }

  .col + .col {
    margin-top: rem(26);

    @media (min-width: $sm) {
      &.col-sm-6 {
        margin-top: 0;
      }
    }

    @media (min-width: $md) {
      margin-top: 0;
    }
  }

  @media (min-width: $md) {
    .row.no-gutters {
      margin-right: -5px;
      margin-left: -5px;

      > .col, > [class*=col-] {
        padding-left: 5px;
        padding-right: 5px;
      }

      input.input-suffix, select.input-suffix {
        width: calc(100% - 40px);
      }
    }
  }

  .form-actions {
    margin-top: rem(30);
  }
}

// Errors and Validation

#{$all-text-inputs},
input[type="checkbox"],
input[type="radio"]
textarea,
select {
  &.is-invalid,
  .was-validated &:invalid,
  &.freeform-has-errors {
    border-color: $color-error;
  }

  &.is-valid,
  .was-validated &:valid {
    //border-color: $color-success;
  }

  &.is-invalid ~ .invalid-feedback,
  .was-validated &:invalid ~ .invalid-feedback {
    display: block;
  }
}

// Inline error messages
.invalid-feedback,
.freeform-errors {
  margin: 4px 0 0;
  padding: 0;
  font-size: rem(13);
  color: $color-error;
  list-style-type: none;

  li {
    margin: 0;
    padding: 0;
  }
}

.invalid-feedback {
  display: none;
}

.row.is-error-indented .freeform-errors {
  margin-left: 11px;

  @media (min-width: $lg) {
    margin-left: 17px;
  }
}

// Boxed user message
.alert,
.freeform-form-errors {
  border:1px solid $color-error;
  color: $color-error;
  background: $white;
  padding: 20px;
  margin-bottom: -30px;

  > p {
    margin: 0;
  }
}

// Instruction text
.form-text {
  font-size: rem(13);

  label + & {
    display: block;
    margin: -4px 0 4px;
  }
}

// UX for repeating rows

.form-repeating-row {
  position: relative;
  padding-right: 20px;

  .btn-remove-row {
    position: absolute;
    right: 0;
    top: calc(50% - 0.5em);
    color: $color-secondary;
    font-size: rem(14);
  }
}
