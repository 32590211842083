// utilities.scss
//
// Text, visibility, and responsive helpers.

// Text alignment helpers
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

// Text color helpers
.text-primary {
  color: $color-primary;
}
.text-secondary {
  color: $color-secondary;
}
.text-light,
.text-muted {
  color: $text-color-light;
}

.nowrap, .nobr, .text-nowrap {
  white-space: nowrap;
}

// Font weight helpers
.font-weight-base {
  font-weight: $font-weight-base !important;
}
.font-weight-normal,
.font-weight-book {
  font-weight: $font-weight-normal !important;
}
.font-weight-medium {
  font-weight: $font-weight-medium !important;
}
.font-weight-semibold {
  font-weight: $font-weight-semibold !important;
}
.font-weight-bold,
.font-weight-heavy {
  font-weight: $font-weight-bold !important;
}

.font-family-base {
  font-family: $font-family-base;
}
.font-family-headline {
  font-family: $font-family-headline;
}
.font-family-display {
  font-family: $font-family-display;
}

.bg-white {
  background-color: $white;
}

.bg-gray {
  background-color: $gray-light
}

// Positioning helpers
.clearfix {
  @include clearfix();
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}

// Visibility helpers
.hidden, .hide {
    display: none !important;
}

.js .js-hide, .js-more {
  display: none;
}

.js .js-more {
  display: block;
}

.sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

// Lazyload image fades
.lazyload,
.lazyloading {
    opacity: 0;
}
.lazyloaded {
    opacity: 1;
    transition: opacity 200ms;
}

.lazyload.lazynofade,
.lazyloading.lazynofade {
    opacity: 1;
}

// Responsive Helpers
// Example: .hide-sm-up, .hide-md-down
@each $breakpoint in map-keys($grid-breakpoints) {
  $min: map-get($grid-breakpoints, $breakpoint);
  $max: ($min - 1);
  @media (min-width: $min) {
    .hide-#{$breakpoint}-up {
      display: none !important;
    }
  }
  @media (max-width: $max) {
    .hide-#{$breakpoint}-down {
      display: none !important;
    }
  }
  @media (min-width: $min) and (max-width: $max) {
    .hide-#{$breakpoint}-only {
      display: none !important;
    }
  }
}

// Bootstrap-style Spacing helpers
// https://getbootstrap.com/docs/4.1/utilities/spacing/
// Example: .mx-5, .mb-md-5, mr-2
@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}-#{$breakpoint}-#{$size} { #{$prop}: $length !important; }
        .#{$abbrev}t-#{$breakpoint}-#{$size},
        .#{$abbrev}y-#{$breakpoint}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r-#{$breakpoint}-#{$size},
        .#{$abbrev}x-#{$breakpoint}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b-#{$breakpoint}-#{$size},
        .#{$abbrev}y-#{$breakpoint}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l-#{$breakpoint}-#{$size},
        .#{$abbrev}x-#{$breakpoint}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }
  }
}

// Debug helper - extend to debug an element
%debug, .debug {
  border: 1px solid red;
}

// Collapse and fade transitions
.fade {
  transition: opacity .15s linear;

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease;

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

// Dropdown - maybe move this

.dropdown {
  position: relative;
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  //min-width: $dropdown-min-width;
}

.dropdown-menu.show {
  display: block;
}

// When enabled Popper.js, reset basic dropdown position
// stylelint-disable-next-line no-duplicate-selectors
.dropdown-menu {
  &[x-placement^="top"],
  &[x-placement^="right"],
  &[x-placement^="bottom"],
  &[x-placement^="left"] {
    right: auto;
    bottom: auto;
  }
}
