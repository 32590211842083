// styleguide.scss
//
// Style Guide styles

.styleguide {
  code {
    display: inline-block;
    background: $gray-light;
    color: $color-primary;
    font-size: 12px;
    padding: 0 2px;
  }
}

.sg-header {
  margin: 4em 0 2em;
  padding: 0.75em 0;
  border-bottom: 1px solid $gray-light;
  border-top: 1px solid $gray-light;
  h5 {
    margin: 0;
    padding: 0;
    font-size: 1em;
    line-height: 1;
    font-weight: bold;
    color: #888;
    text-transform: uppercase;

    a {
      font-weight: bold;
      display: inline-block;
      padding: 0 4px;
      text-decoration: none;
      color: $gray;

      &:hover {
        color: $link-color;
      }
    }
  }

  p {
    margin: 0;
  }
}

.sg-swatches {}

.sg-swatch {

  margin-bottom: 20px;

  .sg-color {
    display: block;
    width: 100%;
    height: 130px;
    padding: 4px;
  }

  .sg-label {
    padding: 8px;
    font-size: 15px;
    line-height: 1;
    background: #fff;
  }
}

.sg-icons {
  @include list-reset();
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 5px;
  }

  .icon {
    font-size: 2rem;
    color: $color-secondary;
  }
}

.row.sg-show-grid {
  > div > * {
    width: 100%;
    background: lighten($color-primary, 40%);
    text-align: center;
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
