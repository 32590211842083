// _articles.scss
//
// News articles and listings

.articles {
  overflow: hidden;
  margin-top: 10px;

  @media (min-width: $lg) {
    margin-top: 48px;
  }
}

.article {
  border-top: 1px solid $color-secondary;
  padding: 1.25em 0 2.25em;

  .article__info, .article__meta {
    font-size: 14px;

    a {
      display: inline-block;
      margin-right: 1em;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    time {
      font-weight: $font-weight-semibold;
      color: $color-secondary;
    }
  }

  // detail page-specific

  .article__meta time {
    display: block;

    @media (min-width: $sm) {
      display: inline;
      & + a {
        margin-left: 1em;
      }
    }
  }

  // listing page-specific

  .article__title {
    margin: 0 0 rem(18);

    a:hover {
      text-decoration: none;
      color: $link-color;
    }
  }

  .article__subhead {
    margin: 0 0 rem(17);
  }

  .article__meta {
    margin-bottom: rem(23);
  }

  .article__link {
    margin: rem(17) 0 0;
  }

  .articles & {
    @include make-row();

    .article__info, .article__body {
      @include make-col();
    }

    @media(min-width: $md) {
      padding: 1.25em 0 3.375em;

      .article__info {
        @include make-col(2);
      }

      .article__body {
        @include make-col(10);
      }
    }
  }
}
