// _accordion.scss
//
// Content Accordions

$gray-accordion-hover: #D8D8D8;

.accordion {
  margin-bottom: 20px;

  &:not(:first-child) {
    margin-top: 40px;

    @media (min-width: $md) {
      margin-top: 60px;
    }
  }

  + .accordion {
    margin-top: 0;
  }

  .col-sidebar & {
    margin-top: 30px;

    @media (min-width: $lg) {
      margin-left: -32px;
    }
  }

  &--subnav {
    @media (min-width: $md) {
      max-width: calc(50% - 11px - 15px);
    }

    @media (min-width: $lg) {
      max-width: calc(100% + 32px);
    }
  }
}

.accordion__item {
  padding-bottom: 10px;

  @media (min-width: $md) {
    padding-bottom: 30px;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.accordion__heading {
  font-size: rem(18);
  line-height: (24/18);
  margin: 0;
}

.accordion__btn {
  display: flex;
  align-items: center;
  appearance: none;
  border: 0;
  color: $color-secondary;
  padding: 15px 20px;
  font-weight: $font-weight-bold;
  font-size: inherit;
  line-height: inherit;
  width: 100%;
  text-align: left;
  background: $gray-light;

  &:hover,
  &:focus {
    outline: 0 none;

    background: $gray-accordion-hover;
  }

  @media (min-width: $sm) {
    padding: 15px 30px;
  }
}

.accordion__icon {
  flex: 0 0 20px;
  margin: 0 0 0 auto;

  .icon {
    display: block;
    color: $color-secondary;
    margin-left: 15px;
    width: 20px;
    height: 20px;
  }

  .icon-plus {
    display: none;
  }

  .collapsed & {
    .icon-plus {
      display: block;
    }

    .icon-minus {
      display: none;
    }
  }
}

.accordion__body {
  background-color: $white;
  padding: 15px 20px;

  @media (min-width: $sm) {
    padding: 30px;
  }

  form & {
    padding-bottom: 30px;

    @media (min-width: $md) {
       padding-bottom: 50px;
    }
  }

  > h6:first-child {
    margin-bottom: 1rem;
  }

  > p:last-child,
  > ul:last-child,
  > ul:last-child > li:last-child {
    margin-bottom: 0;
  }
}
