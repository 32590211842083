// buttons.scss
//
// Button styles

.btn {
  display: inline-block;
  outline: 0;
  background-color: $color-primary;
  border: 1px solid $color-primary;
  border-radius: $btn-border-radius;
  color: $white;
  padding: rem(17) rem(40);
  font-size: 1rem;
  letter-spacing: (1.14/16) * 1em;
  line-height: 1;
  font-family: $font-family-display;
  font-weight: $font-weight-bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all $transition-button;
  cursor: pointer;
  @include font-smoothing(on);

  &:hover:not(:disabled), &:focus:not(:disabled) {
    background-color: $white;
    color: $color-primary;
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.btn-primary {
    // default style is primary
  }

  &.btn-secondary {
    background-color: $color-secondary;
    border-color: $color-secondary;

    &:hover:not(:disabled), &:focus:not(:disabled) {
      background-color: $white;
      color: $color-secondary;
    }
  }

  // sizing

  &.btn-small {
    font-size: rem(14);
    padding: 11px 20px;
  }

  // Force button to full-width of parent
  &.btn-block {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.btn + .btn {
  margin-left: 15px;
}

// Icons applied automatcially by class
.btn-external {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Ctitle%3Eicon-external%3C/title%3E%3Cpolygon points='3.15 0.65 3.15 2.65 7.94 2.65 0.65 9.94 2.06 11.35 9.35 4.06 9.35 8.85 11.35 8.85 11.35 0.65 3.15 0.65' fill='%23#{ str-slice(#{$white}, 2, -1) }' /%3E%3C/svg%3E");
  background-position: calc(100% - 15px) 50%;

  &, &.btn-small {
    padding-right: 35px;
  }

  &:hover, &:focus {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Ctitle%3Eicon-external%3C/title%3E%3Cpolygon points='3.15 0.65 3.15 2.65 7.94 2.65 0.65 9.94 2.06 11.35 9.35 4.06 9.35 8.85 11.35 8.85 11.35 0.65 3.15 0.65' fill='%23#{ str-slice(#{$color-primary}, 2, -1) }' /%3E%3C/svg%3E");
  }
}

.btn-action {
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg height='10' viewBox='0 0 10 10' width='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m362 14v10l4.544514-2.2723278 5.455486-2.7278282z' fill='%23#{ str-slice(#{$white}, 2, -1) }' fill-rule='evenodd' transform='matrix(1 0 0 -1 -362 24)'/%3E%3C/svg%3E");
  background-position: calc(100% - 15px) 50%;

  &, &.btn-small {
    padding-right: 35px;
  }

  &:hover, &:focus {
    background-image: url("data:image/svg+xml,%3Csvg height='10' viewBox='0 0 10 10' width='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m362 14v10l4.544514-2.2723278 5.455486-2.7278282z' fill='%23#{ str-slice(#{$color-primary}, 2, -1) }' fill-rule='evenodd' transform='matrix(1 0 0 -1 -362 24)'/%3E%3C/svg%3E");
  }
}

// Text only or text with icon only button - do not add `.btn` class
.btn-link {
  border: 0;
  background: none;
  padding: 0;
  appearance: none;
  color: $link-color;
  cursor: pointer;
  font-family: $font-family-display;
  font-weight: $font-weight-bold;
  font-size: rem(14);
  letter-spacing: (0.8/14) * 1em;
  line-height: (24/14);
  text-transform: uppercase;

  &:hover:not(:disabled), &:focus:not(:disabled) {
    color: $link-color-hover;
    outline: 0;
    background: none;
  }

  &.btn-link-secondary {
    color: $color-secondary;

    &:hover, &:focus {
      //color: $link-color-hover;
    }
  }

  .icon {
    position: relative;
    top: 2px;
    margin: 0 4px;
  }

  &:disabled {
    color: $gray !important;
    cursor: default;
  }
}
