// variables.scss
//
// Project variables

// Colors
$color-navy: #151450;
$color-red: #B70A0D;

$theme-colors: (
  'yellow': #DA7D00,
  'orange': #EA5C24,
  'maroon': #89080A,
  'lime': #6DA020,
  'green': #179D37,
  'forest': #0C4F29,
  'blue': #3496D0,
  'slate': #43719C,
  'magenta': #9A1874,
  'grape': #441483,
);

$color-primary: map-get($theme-colors, 'blue');
$color-secondary: $color-navy;
$color-brand: $color-red;

$gray: #9b9b9b;
$gray-alt: #595959; // light gray text
$gray-light: #E6E6E6;
$gray-lighter: #F2F2F2;
$gray-lightest: #FAFAFA;
$gray-dark: #787887;
$gray-dark2: #8A8A8A; // lighter text over dark
$gray-darker: #4a4a4a;

$white: #ffffff;

// Text and link colors
$text-color: $gray-dark;
$text-color-light: $gray;

$link-color: $color-primary;
$link-color-hover: $color-secondary;

// Highlight and feedback colors
$color-highlight: #f8f2c6;
$color-error: $color-red;
$color-success:  map-get($theme-colors, 'green');
$color-inactive: $gray;

// This theme is not red so we are using the primary color for label and focus color.
$input-active-color: $color-primary;

// Button SVG encoding colors
$btn-icon-color: ffffff;

// Font stacks
$font-weight-normal: 400;   // regular
$font-weight-book: 400;     // book
$font-weight-medium: 500;   // medium
$font-weight-semibold: 600; // semibold
$font-weight-bold: 700;     // bold
$font-weight-heavy: 700;    // heavy

$font-open-sans: open-sans, sans-serif;
$font-futura-pt: futura-pt, serif;
$font-futura-pt-condensed: futura-pt-condensed, sans-serif;

$font-family-base: $font-open-sans;
$font-family-headline: $font-futura-pt-condensed;
$font-family-display: $font-futura-pt;
$font-weight-base: $font-weight-normal;

// Type defaults
$font-size-base: 1rem !default; // assumes the browser default is `16px`
$line-height-base: 1.5;

// Transition defaults
$transition-link: .1s linear;
$transition-button: .15s linear;

// Border radius
$btn-border-radius: 25px;
$input-border-radius: 0;

// Breakpoints
$xs: 375px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$grid-breakpoints: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl
) !default;

$grid-columns: 12 !default;
$grid-gutter-width: 35px !default;
$grid-gutter-width-sm: 22px;
$container-max-width: 1180px; // 1120 + outer gutter
$container-gutter-width: 30px;
$container-gutter-width-sm: 15px;

// Bootstrap-style spacing helpers
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 4),
  10: .625rem,
  20: 1.25rem,
  30: 1.875rem,
  40: 2.5rem
) !default;
