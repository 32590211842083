// social-icons.scss
//

.social-icons {
	white-space: nowrap;
  margin-top: .3750rem;
}

.social-icon {
	color: #fff;
  display: inline-block;
	width: 2.25em;
  height: 2.25em;
	stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;

  &:hover {
  	color: rgba(255, 255, 255, 0.85);
  }
}

// Facebook #0481D9
#social-icon-facebook {
	[class$="background"] {
		fill: currentColor;
	}

	[class$="foreground"] {
  	fill: transparent;  		
	}
}

// LinkedIn #0481D9
#social-icon-linkedin {
	[class$="background"] {
		fill: currentColor;
	}

	[class$="foreground"] {
  	fill: transparent;
	}	
}

// Twitter #29C5F6
#social-icon-twitter {
	[class$="background"] {
		fill: currentColor;
	}

	[class$="foreground"] {
  	fill: transparent;
	}
}

// Instagram #EB5350
#social-icon-instagram {
	[class$="background"] {
		fill: currentColor;
	}

	[class$="foreground"] {
  	fill: transparent;
	}
}

// YouTube #EB5350
#social-icon-youtube {
	[class$="background"] {
		fill: currentColor;
	}

	[class$="foreground"] {
  	fill: transparent;  		
	}
}