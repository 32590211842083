table {
  margin: 2.5em 0;
  width: 100%;
  border-collapse: collapse;
}

table th, table td {
  font-size: rem(16);
  line-height: 24/16;
  font-weight: normal;
  padding: 16px 25px;
  background: $white;
  border-bottom: 2px solid $gray-lighter;
}

table tr:nth-child(even) th,
table tr:nth-child(even) td {
  background: $gray-lightest;
}

table th {
  font-weight: $font-weight-semibold;
  text-align: left;
  color: $color-secondary;
  padding-top: 10px;
  padding-bottom: 10px;
}

table thead th {
  font-size: rem(12);
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: (0.8/12) * 1em;
  background: $gray-light;
  border: 0;
}
