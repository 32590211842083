// _page.scss
//
// Page component spacing.
// This is mostly vertical spacing for various pagebuilder blocks combinations
// and sidebar/content combinations.
// We mostly use margins instead of padding so adjacent margins can collapse.

.page-section {

  // default stacked section spacing
  &:first-child {
    margin-top: 40px;
  }

  &:last-child {
    margin-bottom: 40px;
  }

  @media (min-width: $md) {
    &:first-child {
      margin-top: 60px;
    }

    &:last-child {
      margin-bottom: 62px;
    }
  }

  @media (min-width: $lg) {
    &:first-child {
      margin-top: 78px;
    }
  }

  .page-section + & {
    margin-top: rem(26);

    @media (min-width: $lg) {
      margin-top: rem(50);
    }
  }

  // top margin when subnav is present
  .page-subnav + & {
    margin-top: rem(40);
  }

  .col-sidebar h2 {
    margin-top: rem(5);
  }
}

// single page content layout section
.page-content {
  margin-top: 40px;

  &:last-child {
    margin-bottom: 40px;
  }

  @media (min-width: $lg) {
    margin-top: 48px;
  }
}

.page-content > .page-section:first-child {
  margin-top: 0;
}

.page-content > .page-section:last-child {
  margin-bottom: 0;
}

.page-article {
  @media (max-width: $lg - 1px) {
    margin-top: 10px;
  }
}

.page-section .container, .page-content .container {
  position: relative; // back-to-top link fix
}


// Global white boxes
.box {
  background-color: $white;
  padding: 20px;
  margin: 20px 0;
  position: relative;
  z-index: 3;

  @media (min-width: $md) {
    padding: 30px;
    margin: 30px 0;
  }

  form &,
  .col-content form & {
    padding-bottom: 30px;
    margin-bottom: 0; // .form-actions applies top margin;

    @media (min-width: $md) {
       padding-bottom: 50px;
       margin-bottom: 0;
    }
  }

  .col-sidebar & {
    @media (min-width: $lg) {
      margin-left: -32px;
    }
  }

  .col-sidebar &:first-child {
    @media (min-width: $md) {
      margin-top: -70px;
    }

    &.box--top {
      @media (min-width: $md) {
        margin-top: 48px;
      }
    }
  }

  .col-content & {
    margin: rem(26) 0;

    @media (min-width: $lg) {
      margin: rem(50) 0;
    }
  }

  .page-content &:first-child {
    margin-top: 0;
  }

  &.box--subnav-align {
    @media (min-width: $md) and (max-width: $lg - 1px) {
      max-width: calc(50% - 11px - 15px);
    }
  }

  > p:last-child,
  > ul:last-child,
  > ul:last-child > li:last-child {
    margin-bottom: 0;
  }
}

.box__heading,
.box__heading:first-child {
  margin: -20px -20px 20px;

  @media (min-width: $md) {
    margin: -30px -30px 30px;
  }
}

.box__heading{
  font-size: rem(18);
  line-height: (24/18);
  color: $color-secondary;
  padding: 15px 20px;
  font-weight: $font-weight-bold;
  background: $gray-light;
  text-transform: none;
  font-family: inherit;

  @media (min-width: $md) {
    padding: 15px 30px;
  }
}

// Gobal link list in sidebars
.link-list {
  @include list-reset();

  li {
    margin: .1875rem 0 .625rem;
  }

  li.active a {
    color: $color-secondary;
    font-weight: $font-weight-semibold;
  }

  .icon {
    font-size: 8px;
    margin-left: 5px;
  }
}
