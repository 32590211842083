// _cards.scss
//
// Leadership card grid

.cards {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	position: relative;
  margin-left: -10px;
  margin-right: -10px;

	@media (min-width: $md) {
		margin-left: -16px;
    margin-right: -16px;
	}

	.card {
		width: calc(50% - 20px);
		margin: 0 10px 20px;
		background: #fff;

		&:hover {
			box-shadow: 0 2px 15px rgba(0,0,0, 0.15);
		}
		
		@media (min-width: $md) {
			width: calc(33.33333% - 32px);
			margin: 0 16px 32px;
		}

		@media (min-width: $lg) {
			width: calc(25% - 32px);
		}

	}

	.card.card-noimg {
		flex: none;
		align-self: flex-start;

		.card-body {
			min-height: 131px;

			@media (min-width: $md) {
				min-height: 121px;
			}
		}
	}

	.card-img {
		display: block;
	}

	.card-body {
		padding: 1.25em 1em;

		h2 {
			margin: 0;
			font-size: 1.25rem;
			margin-bottom: 0.125em;
		}
    
		p { 
			font-size: rem(14);
			line-height: 1.5;
			margin: 0; 
		}

		@media(min-width: $xs) {
			h2 {
				font-size: rem(24);
			}

			p {
				font-size: 1rem;
			}
		}
	}

	a {
		text-decoration: none;

		h2 { 
      color: $color-secondary; 
    }

		p { 
      color: $text-color; 
    }
	}
}