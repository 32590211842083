// base.scss
//
//  Base styles and typography

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, button, input, select, textarea {
  color: $text-color;
  font-family: $font-family-base;
  @include font-smoothing(on);
}

// html, body {
//   width: 100%;
//   overflow-x: hidden;
// }

.header-wrapper {
  background: $gray-darker;
}

.main {
  min-height: 70vh;

  > .container {
    &:last-of-type {
      padding-bottom: 80px;
    }
  }
}

body {
  background: $gray-lighter;
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-weight: $font-weight-base;
}

::selection {
  background: $color-highlight;
  text-shadow: none;
}

a {
  color: $link-color;
  text-decoration: none;
  transition: color $transition-link;
  outline: 0;

  &:hover, &:focus {
    color: $link-color-hover;
    text-decoration: underline;
  }

  &.plain {
    color: inherit;
    font-weight: inherit;
    text-decoration: none;

    &:hover {
      color: $link-color;
    }
  }
}

.more,
%more {
  display: inline-block;
  font-size: rem(14);
  font-weight: $font-weight-bold;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: (1/14) * 1em;
}

strong {
  //font-weight: $font-weight-bold;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid $gray-light;
  margin: rem(32) 0;
  padding: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

code, kbd, pre, samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

mark, .mark {
  background-color: $color-highlight;
}

small, .small {
  font-size: .9375rem; // 15px
}

big, .big, .large {
  font-size: 1.25rem; // 20px

  @media (min-width: $lg) {
    font-size: 1.5rem; // 25px
  }
}


// Headings
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
%h1, %h2, %h3, %h4, %h5, %h6 {
  color: $color-secondary;
  font-family: $font-family-headline;
  font-weight: $font-weight-bold;
  margin: 1.875rem 0 0.25rem;
  padding: 0;

  a {
    color: inherit;
    border: 0;
  }

  &:first-child {
    margin-top: 0
  }

  &.title {
    margin-bottom: 4px;
  }

  &.byline {
    margin-top: 0;
  }

  small, .small {
    font-size: 65%;
  }
}

h1, .h1, %h1 {
  font-size: rem(42);
  line-height: (38/42);
  letter-spacing: (0.6/42) * 1em;
  text-transform: uppercase;

  @media (min-width: $md) {
    font-size: rem(64);
    line-height: (60/64);
  }

  @media (min-width: $lg) {
    font-size: rem(80);
    line-height: (76/80);
    letter-spacing: (1.24/80) * 1em;
  }
}

h2, .h2, %h2 {
  font-size: rem(32);
  line-height: (34/32);
  margin-bottom: rem(16);
  text-transform: uppercase;
}

h3, .h3, %h3 {
  font-family: $font-family-base;
  font-size: rem(18);
  line-height: (24/18);
  margin-bottom: rem(16);
  text-transform: none;
}

h4, .h4, %h4 {
  color: $color-primary;
  font-family: $font-family-display;
  font-weight: $font-weight-medium;
  font-size: rem(20);
  line-height: (27/20);
  text-transform: uppercase;
  text-transform: none;
}

h5, .h5, %h5 {
  font-family: $font-family-base;
  color: $color-primary;
  font-size: rem(10);
  line-height: $line-height-base;
  text-transform: uppercase;
  letter-spacing: (0.8/10) * 1em;
  margin: 0 0 4px;
}

h6, .h6, %h6, p.heading, .lead, %lead {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-semibold;
  line-height: $line-height-base;
  margin: 0 0 4px;
  text-transform: none;
}

.lead, %lead {
  color: $color-secondary;
  font-weight: $font-weight-semibold;
  text-transform: none;
  font-size: rem(24);
  line-height: (36/30);

  @media (min-width: $md) {
    font-size: rem(30);
    font-weight: $font-weight-normal;
    padding-right: 15%;
    line-height: (38/30);
  }

  @media (min-width: $lg) {
    font-size: rem(35);
  }
}


// General margins
ul, ol, dl, p, blockquote {
  margin: rem(3) 0 rem(15);
}

// Lists
ul,
ol {
  padding: 0 0 0 20px;
}

ol {
  padding: 0 0 0 22px;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin-top: rem(12);
  margin-bottom: rem(6);
}

li {
  margin-bottom: rem(6);
  line-height: (28/17);
}

ul.list-unstyled, ul.list-plain {
  list-style: none;
  padding: 0;

  a {
    color: $text-color;
    text-decoration: none;
  }
}

nav ul, nav ol {
  @include list-reset();
}


dt, dd {
  margin-bottom: 0.1875em;
  line-height: 1.3125;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

figure {
  margin: rem(15) 0;

  @media (min-width: $md) {
    margin-top: rem(32);
  }

  @media (min-width: $lg) {
    margin-top: rem(46);
  }

  &:first-child {
    margin-top: 0;
  }
}

figcaption {
  font-size: rem(14);
  line-height: (22/14);
  margin-top: rem(10);

  @media (min-width: $lg) {
    margin-top: rem(20);
  }
}

// blockquotes
blockquote {
  color: $color-secondary;
  font-size: rem(24);
  line-height: (34/24);

  p, footer, cite {
    font-size: inherit;
    line-height: inherit;
    font-style: italic;
    font-weight: $font-weight-base;
    text-align: left;
  }

  &.quote {
    p:first-of-type::before {
      content: "“";
      display: inline;
      // hanging quotation mark
      margin-left: -.5em;
      float: left;
    }

    p:last-of-type::after {
      content: "”";
      display: inline;
    }
  }

  cite {
    font-family: $font-family-display;
    font-style: normal;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    font-size: 80%;
  }
  cite:before {
    content: "— ";
  }
}

