// responsive-video.scss

.video-responsive,
.embed-responsive,
%embed-responsive {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; /* For ratio 16:9. 75% if ratio is 4:3 */
  position: relative;

  embed,
  object,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
