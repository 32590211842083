// footer.scss
//
// Site footer styles

$nav-break-down: ($md - 1);

$nav-footer-break: 800px;

.site-footer {
  background: $color-secondary;
  color: $gray-dark2;
  font-size: rem(16);
  line-height: (21/16);

  a, a:hover, a:active {
    color: $white;
    font-weight: $font-weight-normal;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition-property: all;
  }

  a:hover, a:active {
    border-bottom: 1px solid rgba($white, 0.9);
  }

  .h4 {
    color: $gray-dark2;
    font-size: rem(16);
    line-height: (21/16);
    margin: 0 0 rem(16);
    text-transform: uppercase;
  }

  .nav-footer__main {
    ul {
      border-bottom: 2px solid $gray-dark;
      padding: 8px 0;

      @media (min-width: $nav-footer-break) {
        display: flex;
        justify-content: space-between;
      }
    }

    li {
      margin: rem(26) 0;
    }

    a {
      display: block;
      width: 100%;
      font-family: $font-family-display;
      font-weight: $font-weight-heavy;
      font-size: rem(18);
      line-height: (24/18);
      text-transform: uppercase;
      position: relative;

      @media (min-width: $nav-footer-break) {
        font-size: rem(20);
        line-height: (26/20);
      }

      .icon {
        font-size: 15px;
        position: relative;
        top: 2px;
        margin-left: 7px;
      }

      // Thinner arrow on mobile and desktop, both are in the markup
      .icon-external {
        display: none;
      }

      @media (min-width: $lg) {
        .icon-external {
          display: inline-block;
        }

        .icon-external-alt {
          display: none;
        }
      }
    }
  }

  .nav-footer__wrap {
    padding-bottom: 25px;

    @media (min-width: $lg) {
      @include make-row();
    }
  }

  .nav-footer__sitemap {

    @include make-row();
    padding-top: 48px;
    display: none;

    @media (min-width: $md) {
      display: flex;
    }

    @media (min-width: $lg) {
      @include make-col(8);
    }

    .col {
      @include make-col();
      margin-bottom: rem(26);

      @media (min-width: $md) {
        @include make-col(4);
      }
    }

    li {
      margin: rem(18) 0;
    }
  }

  .nav-footer__sitemap,
  .nav-footer__info {
    .icon {
      font-size: 10px;
      position: relative;
      top: 1px;
      margin-left: 7px;
    }
  }

  .nav-footer__info {
    @include make-row();
    padding-top: 40px;

    @media (min-width: $md) {
      border-top: 2px solid $gray-dark;
      padding-top: 40px;
    }

    @media (min-width: $lg) {
      @include make-col(4);
      border-top: 0;
      display: block;
      position: relative;
      padding-top: 45px;

      // vertical divider rule
      &::before {
        content: " ";
        position: absolute;
        top: 48px;
        left: 35px;
        height: calc(100% - 96px);
        border-left: 2px solid $gray-dark;
      }

    }

    .col {
      @include make-col();
      margin-bottom: rem(26);

      @media (min-width: $md) and (max-width: $lg - 1px) {
        @include make-col(4);
      }

      @media (min-width: $lg) {
        padding-left: 55px;
        margin-bottom: rem(42);
      }
    }

    p {
      margin-bottom: 0.675em;
    }

    li {
      display: inline-block;
    }
    li + li {
      margin-left: 20px;
    }
  }
}
