// _subnav.scss
//
// Page subnavigation
// Only displays on larger screens.

.subnav {
  display: none;
  background: #fff;
  position: relative;
  z-index: 3;

  @media (min-width: $md) {
    margin-top: -70px;
    //max-width: calc(50% - 11px - 15px);
  }

  @media (min-width: $lg) {
    margin-left: -32px;
    //max-width: calc(100% + 32px);
  }

  li {
    border-bottom: 1px solid #dadada;
    &:last-child { border: none; }
  }

  a {
    display: block;
    color: $color-primary;
    font-family: $font-family-display;
    font-size: rem(16);
    font-weight: $font-weight-bold;
    letter-spacing: (0.8/16) * 1em;
    text-transform: uppercase;
    line-height: 1;
    padding: 19px 30px 18px 55px;
    position: relative;
    @include font-smoothing(off);

    &:hover {
      text-decoration: none;
      color: $color-secondary;
    }
  }

  .icon {
    font-size: 12px;
    position: relative;
    top: 1px;
    margin-left: 5px;
  }

  .active a {
    text-decoration: none;
    color: $color-secondary;
    font-weight: $font-weight-bold;
    @include font-smoothing(on);

    &:after {
      content: "";
      position: absolute;
      left: 35px;
      top: 50%;
      transform: translateY(-50%);
      @include triangle('right', 10px, 10px, $color-secondary);
    }
  }

  @media (min-width: $md) {
    display: block;
  }
}
