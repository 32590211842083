// _top-link.scss
//
// Back to top link

a.top-link {
  font-family: $font-family-display;
  font-size: rem(14);
  line-height: (24/14);
  font-weight: $font-weight-heavy;
  text-transform: uppercase;
  letter-spacing: (1/14) * 1em;
  visibility: hidden;
  display: inline-block;
  opacity: 0;
  transition: opacity 0.6s, transform 0.3s;
  transform: translateY(200%);

  .icon {
    font-size: 12px;
    position: relative;
    top: 0px;
    margin-right: 5px;
  }

  // scrolling
  &.fixed {
    position: fixed;
    left: auto;
  }

  // pinned to bottom above footer
  &.stuck {
    position: absolute;
    left: 0;
  }

  // only show on large screens
  @media (min-width: $lg) {
    &.fixed, &.stuck {
      visibility: visible;
      opacity: 1;
      left: auto;
      bottom: 35px;
      transform: translateY(0);
    }
  }
}
