// icons.scss
//
// Single-colored icons can be modified like so:
// .icon-name {
//   font-size: 32px;
//   color: red;
// }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon-arrow-left {
  transform: rotate(-90deg);
}
